@import "system/variables";
/******  HELPERS  ******/
.color-brand {
    color: $brand;
  }

  .color-white {
    color: #fff;
  }
.color-black{
  color: #000;
}
.color-yellow{
  color: #fbcc5b;
}
  .text-center {
    text-align: center;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

