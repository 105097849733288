@import 'system/variables';
@import 'system/mixin';

img {
  width: 100%;
}
p {
  //   font-size: 20px;
  @include vw($font-size: 20);
  color: #878787;
  font-family: $ff;
  line-height: 1.25;
}
a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}
strong {
  font-family: $ff-bold;
}
.mobile-visible {
  display: none;
  @include respond-to(xs) {
    display: block;
  }
}
.desktop-visible {
  display: block;
  @include respond-to(xs) {
    display: none;
  }
}
.site-wrap {
  transform: translateY(0%);

  opacity: 1;
  transition: all 0.6s ease;
}
.container {
  @include vw($max-width: 1280, $padding-right: 15, $padding-left: 15);
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  @include respond-to(xs) {
    padding-right: 20px;
    padding-left: 20px;
  }
}
.btn {
  @include vw($font-size: 24, $border: 3 solid #000, $padding: 5 10);
  font-family: $ff-bold;
  line-height: 1.15;
  border-radius: 0;
  @include respond-to(xs) {
    font-size: 15px;
  }
  &--bg {
    background: $bg;
    //   font-size: 22px;
    @include vw($font-size: 22, $padding: 9 10, $margin: 60 0 0);
    color: #000;
    border-color: transparent;
    font-family: $ff;
    @include respond-to(xs) {
      font-size: 9px;
      padding: 6px 10px;
      margin: 0px 0 0;
      border: 0;
    }
  }
  &__text {
    font-family: $ff;
    // padding-right:  8px;
    @include vw($padding-right: 8);
    @include respond-to(xs) {
      padding-right: 0;
    }
  }
  &__arrow {
    font-family: $ff;
    // padding-right:  8px;
    @include vw($padding-right: 8);
    transform: translateX(0);
    display: inline-block;
    transition: $trans;
  }
  &:hover {
    .btn__arrow {
      transform: translateX(5px);
    }
  }
}
.title {
  text-align: center;
  font-size: 38px;
  @include vw($font-size: 38);
  @include respond-to(xs) {
    font-size: 17px;
  }
  &--lg {
    @include vw($font-size: 42, $margin-bottom: 66);
    font-family: $ff-bold;
    @include respond-to(xs) {
      font-size: 19px;
      margin-bottom: 20px;
    }
  }
  &--md {
    @include vw($font-size: 30, $margin-bottom: 70);
    font-family: $ff-bold;
    @include respond-to(xs) {
      margin-bottom: 17px;
      font-size: 20px;
    }
  }
}
.card {
  border: 1px solid #707070;
  padding: 50px 103px 103px;
  @include vw($border: 1 solid #707070, $padding: 83 100);
  border-radius: 0;
  @include respond-to(xs) {
    border: 1px solid #000;
    padding: 20px 45px 30px;
  }
  &-descr {
    @include vw($margin-bottom: 65);
    @include respond-to(xs) {
      margin-bottom: 16px;
      line-height: 1.2;
      font-size: 15px;
    }
  }
}
.header {
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding-top: 45px;
    // padding-bottom: 36px;
    @include vw($padding-top: 45, $padding-bottom: 36, $max-width: 1170);
    @include respond-to(xs) {
      padding-top: 50px;
    }
  }
  &__logo {
    // width: 175px;
    @include vw($width: 175);
    @include respond-to(xs) {
      width: 68px;
    }
  }
}
.hero {
  @include vw($margin-bottom: 40);
  @include respond-to(xs) {
    margin-bottom: 25px;
  }
}

.preorder {
  position: relative;
  z-index: 1;
  &__title {
    @include vw($font-size: 35, $margin-bottom: 60);
    font-family: $ff-bold;
    @include respond-to(xs) {
      font-size: 20px;
      width: 65%;
      margin: 0 auto 3px;
      text-align: left;
    }
  }
  &__wrap {
    display: flex;
    @include vw($padding: 0 58);
    @include respond-to(xs) {
      padding: 0 16px;
      flex-wrap: wrap;
    }
  }
  &__img {
    position: relative;
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    @include respond-to(xs) {
      order: 1;
      flex-basis: 100%;
      margin-top: -72px;
      position: relative;
      z-index: 5;
    }
    &-bg {
      border-radius: 50%;
      position: absolute;
      background: $bg;
      z-index: -1;
      @include vw($width: 510, $height: 470, $top: 110, $left: 40);

      @include respond-to(xs) {
        top: 53%;
        left: 50%;
        width: 180px;
        height: 160px;
        transform: translate(-50%, -50%);
      }
    }
    &-img {
      width: 90%;
      text-align: center;
      @include vw($padding-top: 30, $margin: auto);
      @include respond-to(xs) {
        padding-top: 0px;
        width: 190px;
        margin: auto;
      }
    }
  }
  &__card {
    flex-basis: 58%;
    margin-left: -9%;
    @include vw($margin-top: 25);
    @include respond-to(xs) {
      order: 0;
      flex-basis: 100%;
      margin: 17px 0;
    }
    .card {
      @include respond-to(xs) {
        padding: 20px 32px 57px;
      }
    }
    .card-descr {
      @include respond-to(xs) {
        margin-bottom: 3px;
      }
    }
    .btn {
      @include respond-to(xs) {
        border: 1px solid #000;
        font-size: 13px;
        padding: 6px 12px;
      }
    }
  }
}
.safe {
  background: #eee;
  position: relative;
  z-index: 0;
  @include vw($margin-top: -110, $padding-top: 145, $padding-bottom: 70);
  @include respond-to(xs) {
    margin-top: -185px;
    padding-top: 185px;
    padding-bottom: 30px;
  }
  &__title {
    margin-top: 52px;
    margin-bottom: 70px;
    @include vw($margin-top: 45, $margin-bottom: 70);
    font-family: $ff-bold;
    @include respond-to(xs) {
      margin-top: 25px;
      margin-bottom: 20px;
      font-size: 20px;
      margin-bottom: 50px;
    }
  }
  &__icon {
    text-align: center;
    @include vw($height: 65, $margin: auto auto 27);
    @include respond-to(xs) {
      height: 60px;
      margin-bottom: 15px;
    }
    img {
      height: 100%;
      width: auto;
    }
  }
  &__inner {
    justify-content: space-between;
    display: flex;
    @include respond-to(xs) {
      flex-wrap: wrap;
    }
  }

  &__text {
    line-height: 1.5;
    @include vw($font-size: 18);
    @include respond-to(xs) {
      margin: auto;
      line-height: 1.2;
      font-size: 18px;
      text-align: center;
      line-height: 1.45;
    }
  }
  &__col {
    flex-basis: 33%;
    text-align: center;
    @include vw($padding: 0 30);
    @include respond-to(xs) {
      flex-basis: 100%;
      margin-bottom: 60px;
    }
    &-title {
      font-family: $ff-bold;
      margin-bottom: 0;
      @include vw($font-size: 20, $margin-bottom: 10);
      @include respond-to(xs) {
        font-size: 18px;
        text-align: center;
        margin-bottom: 15px;
        margin-top: 43px;
      }
    }

    &:first-child {
      .safe__icon {
        margin: auto;
        text-align: center;
        @include vw($height: 65, $margin-bottom: 27);
        @include respond-to(xs) {
          height: 60px;
          margin-bottom: 15px;
        }
      }
    }
  }
}

.arrow {
  margin: 0 auto;
  @include vw($width: 24);
  @include respond-to(xs) {
    width: 10px;
  }
  img {
    display: block;
  }
}
.line {
  background: #000;
  margin: auto auto 0;
  position: absolute;
  //   bottom: -37px;
  left: 50%;
  @include vw($width: 3, $height: 90, $bottom: -37);
  @include respond-to(xs) {
    width: 2px;
    height: 55px;
    bottom: -25px;
  }
}

.info {
  overflow: hidden;
  @include vw($padding-top: 75);
  @include respond-to(xs) {
    padding-top: 78px;
    padding-bottom: 20px;
  }

  &__title {
    @include vw($margin-bottom: 50);
    @include respond-to(xs) {
      font-size: 27px;
      margin-bottom: 79px;
      text-align: left;
      padding: 0 38px;
      line-height: 1.3;
    }
  }
  &__row {
    display: flex;
    margin-bottom: 160px;
    align-items: center;
    @include vw($margin-bottom: 150);
    @include respond-to(xs) {
      flex-wrap: wrap;
      margin-bottom: 60px;
      flex-wrap: wrap;
      padding-right: 15px;
      padding-left: 15px;
    }
    &--left {
      .info-card {
        flex-basis: 51%;
        flex-grow: 0;
        order: 0;
        margin-right: -6%;
        display: flex;
        @include vw($min-height: 489);
        @include respond-to(xs) {
          flex-basis: 100%;
          min-height: 0;
          margin-bottom: 50px;
          order: 0;
        }
      }
      .info__img {
        order: 0;
        margin-right: -18%;
        flex-basis: 65%;
        @include respond-to(xs) {
          padding: 0;
          flex-basis: 100%;
          order: 2;
        }
      }
    }
  }
  &-card {
    flex-basis: 67%;
    flex-grow: 0;
    order: 0;
    margin-right: -19%;
    @include vw($min-height: 489);
    display: flex;
    margin-left: 1%;
    @include respond-to(xs) {
      order: 0;
      flex-basis: 100%;
      min-height: 0;
      margin-bottom: 50px;
      margin-left: 0;
      margin-right: 0;
    }
    .card {
      min-height: 100%;
      @include vw($padding: 80 56);
      @include respond-to(xs) {
        padding: 15px 20px 25px;
      }
    }
    p {
      //   font-size: 23px;
      @include vw($font-size: 23);
      color: $grey;
      line-height: 1.1;
      @include respond-to(xs) {
        font-size: 16px;
        line-height: 1.6;
        margin-bottom: 11px;
      }
    }
  }
  &__img {
    flex-basis: 80%;
    flex-grow: 0;
    order: 1;
    padding: 40px 0;
    margin-right: 1%;
    @include vw($padding: 40 0);
    @include respond-to(xs) {
      padding: 0;
      flex-basis: 100%;
      order: 2;
      margin: 0;
    }
  }
}

.bg-grey {
  background: $grey-light;
}

.what-is {
  @include vw($padding-top: 60, $padding-bottom: 110);
  position: relative;
  // z-index: 2;
  overflow: visible;
  @include respond-to(xs) {
    margin-top: 0px;
    overflow: visible;
    padding-bottom: 30px;
  }
  .arrow {
    display: none;
  }
  .line {
    @include vw($top: 0, $bottom: auto, $height: 140);
    transform: translateY(-71%);
    @include respond-to(xs) {
      top: 14px;
      bottom: auto;
      height: 63.38px;
    }
  }
  .info__title {
    font-family: $ff-bold;
    @include respond-to(xs) {
      margin-top: 60px;
      margin-bottom: 88px;
      text-align: center;
    }
  }
  .info__row {
    @include vw($margin-bottom: 180, $padding: 0 27);
    @include respond-to(xs) {
      margin-bottom: 60px;
    }
    &:first-child {
      @include respond-to(xs) {
      }
      .info__img {
        margin-right: -2%;
        margin-left: -5%;
        flex-basis: 50%;
        @include vw($margin-top: 75);
        @include respond-to(xs) {
          flex-basis: 80%;
          margin: -85px auto 0;
        }
      }
    }
    &:nth-child(2) {
      @include respond-to(xs) {
        position: relative;
      }

      .info__img {
        @include respond-to(xs) {
          margin-top: -84px;
          margin-bottom: 143px;
          z-index: 9;
          width: 52%;
          margin-right: 0%;
          position: absolute;
          bottom: -30px;
          left: -58px;
        }
        img {
          @include respond-to(xs) {
            transform: rotate(-118deg);
          }
        }
      }
    }
    &:last-child {
      @include vw($margin-bottom: 70);

      @include respond-to(xs) {
        margin-bottom: 20px;
        position: relative;
      }
      .info__img {
        flex-basis: 53%;
        flex-grow: 0;
        order: 1;
        padding: 0px 0;
        margin-right: 0;
        text-align: center;
        margin: auto;
        @include respond-to(xs) {
          overflow: hidden;
          margin-top: 17px;
          position: absolute;
          width: 190px;
          z-index: 9;
          right: -60px;
          transform: rotate(8deg);
          bottom: 20px;
        }
        img {
          transform: translateX(10%);
          width: 70%;
          @include vw($margin-top: 24);
          @include respond-to(xs) {
            transform: translateX(0%);
          }
        }
      }
    }
    &:last-child {
      // margin-bottom: 70px;
    }
  }
  .info-row {
    @include vw($padding: 0 27);
  }
  .info-card {
    flex-basis: 55%;
    margin-right: 0%;
    flex-grow: 0;
    flex-shrink: 0;
    width: 55%;
    max-width: 55%;
    @include vw($min-height: 412);
    @include respond-to(xs) {
      flex-basis: 100%;
      width: 100%;
      max-width: 100%;
    }
    .card {
      @include vw($padding: 60 40);
      @include respond-to(xs) {
        border: 0;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
        padding: 33px 50px 30px;
      }
      .title {
        @include vw($font-size: 30, $margin-bottom: 47);
        @include respond-to(xs) {
          line-height: 1;
          margin-bottom: 20px;
        }
        p {
          width: 85%;
          margin: auto;
          @include respond-to(xs) {
            line-height: 1.15;
          }
        }
      }
      .btn {
        @include respond-to(xs) {
          background: #fff;
          font-size: 16px;
          border-radius: 18px;
          border: 2px solid #000;
          color: #000;
          padding: 3px 5px;
          &__arrow {
            display: none;
          }
        }
      }
      p {
        @include respond-to(xs) {
          margin-bottom: 19px;
          margin-bottom: 19px;
          line-height: 1.3;
        }
      }
    }
  }
  .info__img {
    padding: 0;
  }
  &__footer {
    text-align: center;
    font-family: $ff-bold;
    @include vw($font-size: 23, $margin-top: 210);

    @include respond-to(xs) {
      font-size: 20px;
      font-family: $ff-bold;
      width: 80%;
      margin: 0 auto;
      width: 52%;
      margin: 90px auto 0;
    }
  }
  span {
    display: block;
    margin: 0 auto;
    @include vw($margin: 0 auto 30);
    img {
      @include vw($width: 30);
      @include respond-to(xs) {
        width: 26px;
      }
    }
  }
}
.review {
  @include vw($padding-top: 75, $padding-bottom: 190);
  @include respond-to(xs) {
    padding-top: 15px;
    padding-bottom: 35px;
  }
  .title {
    margin-bottom: 30px;
    @include vw($margin-bottom: 30);
    @include respond-to(xs) {
      margin-bottom: 0;
    }
  }
}
.slider {
  background: url(/images/quote.png) top left no-repeat;
  // padding: 50px 105px;
  position: relative;
  @include vw($padding: 50 105, $background-size: 210);
  @include respond-to(xs) {
    padding: 10px 40px;
    background-size: 42px;
  }
  &__text {
    // font-size: 42px;
    @include vw($font-size: 42);
    color: #000;
    @include respond-to(xs) {
      font-size: 16px;
      color: #4a4a4a;
      font-family: $ff-bold;
    }
  }
  .slick-prev {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-120%, 100%);
    // width: 70px;
    // height: 70px;
    border-radius: 50%;
    background: url(/images/arrow-left.png) center center no-repeat;
    background-size: cover !important;
    @include vw($width: 70, $height: 70);
    @include respond-to(xs) {
      width: 21px;
      height: 21px;
      transform: translate(-124%, 50%);
    }
  }
  .slick-next {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(20%, 100%);
    // width: 70px;
    // height: 70px;
    @include vw($width: 70, $height: 70);
    border-radius: 50%;
    background: url(/images/arrow-right.png) center center no-repeat;
    background-size: cover !important;
    @include respond-to(xs) {
      width: 21px;
      height: 21px;
      transform: translate(24%, 50%);
    }
  }
}

.labs {
  @include vw($padding: 210 0 170);
  @include respond-to(xs) {
    padding: 61px 0 58px;
  }
  &__inner {
    display: flex;
    @include respond-to(xs) {
      flex-wrap: wrap;
      padding-left: 40px;
      padding-right: 36px;
    }
  }
  &__img {
    flex-basis: 50%;
    flex-shrink: 0;
    flex-grow: 0;
    @include respond-to(xs) {
      flex-basis: 100%;

      order: 2;
    }
  }
  &__col {
    padding-left: 8%;
    @include vw($padding-top: 20);
    @include respond-to(xs) {
      flex-basis: 100%;
      padding-left: 0;
    }
    .title--md {
      // font-size: 30px;
      // margin-bottom: 35px;
      @include vw($font-sze: 30, $margin-bottom: 25);
    }
    p {
      margin-bottom: 53px;
      @include vw($margin-bottom: 53, $font-size: 23);
      line-height: 1.1;
      @include respond-to(xs) {
        font-family: $ff-bold;
        font-size: 16px;
        margin-bottom: 19px;
        line-height: 1.35;
      }
    }
  }
}

.footer {
  &__inner {
    display: flex;
    @include vw($padding-top: 22, $padding-bottom: 22, $max-width: 1040);
    @include respond-to(xs) {
      padding-top: 18px;
    }
  }
  &__title {
    text-align: left;
    // margin-bottom: 23px;
    @include vw($margin-bottom: 23);
    @include respond-to(xs) {
      margin-bottom: 3px;
    }
  }
  p {
    @include respond-to(xs) {
      font-size: 16px;
    }
    br {
      display: none;
      @include respond-to(xs) {
        display: inline;
      }
    }
  }
  &__col {
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    @include vw($padding-right: 50);
    &:first-child {
      @include respond-to(xs) {
        display: none;
      }
    }
    &:last-child {
      padding-right: 0;
    }
  }
}

.modal-dialog {
  @include vw($margin: 130 auto, $max-width: 1140);
  border: 0;
  border-radius: 0;
  @include respond-to(xs) {
    margin: 30px auto;
    max-width: 96%;
  }
}
.modal-header {
  background: $brand;
  border-radius: 0;
  @include vw($height: 180);
  float: none;
  text-shadow: none;
  opacity: 1;
  @include respond-to(xs) {
    height: 60px;
  }
}
.modal-content {
  border-radius: 0;
}
.modal-body {
  @include vw($padding: 0);
}
.suppl {
  @include vw($padding: 18 62 50, $font-size: 34);
  font-family: $ff;
  @include respond-to(xs) {
    padding: 20px 10px 8px;
    font-size: 12px;
  }
  &__table {
    @include vw($padding-right: 70);
    @include respond-to(xs) {
      padding-right: 10px;
    }
  }
  &__title {
    @include vw($font-size: 95, $margin-bottom: 20);
    font-family: $ff-bold;
    @include respond-to(xs) {
      font-size: 36px;
      margin-bottom: 15px;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    @include vw($padding-bottom: 5, $border-bottom: 7 solid #000);
    @include respond-to(xs) {
      border-bottom: 3px solid #000;
      padding-bottom: 5px;
    }
  }
  &__subheader {
    display: flex;
    justify-content: flex-end;
    @include vw($border-bottom: 7 solid #000, $padding-bottom: 5);
    @include respond-to(xs) {
      border-bottom: 3px solid #000;
      padding-bottom: 5px;
      padding-top: 5px;
    }
    div {
      &:first-child {
        @include vw($padding-right: 115) @include respond-to(xs) {
          padding-right: 35px;
        }
      }
    }
  }
  &__row {
    @include vw($padding: 13 0 13, $border-bottom: 1 solid #000);
    @include respond-to(xs) {
      border-bottom: 1px solid #000;
      padding: 10px 0;
    }
    p {
      @include vw($font-size: 34);
      font-family: $ff;
      width: 80%;
      color: #000;
      &:last-child {
        @include vw($margin-bottom: 0);
      }
      @include respond-to(xs) {
        font-size: 16px;
      }
    }
  }
  &__footer {
    @include vw($padding: 13 0 13, $border-top: 6 solid #000);
    @include respond-to(xs) {
      border-top: 2px solid #000;
      padding: 10px 0;
    }
    p {
      @include vw($font-size: 34, $margin-bottom: 10);
      font-family: $ff;
      color: #000;
      @include respond-to(xs) {
        font-size: 16px;
        margin-bottom: 10px;
      }
      &:last-child {
        @include vw($margin-bottom: 0);
      }
    }
  }
}
