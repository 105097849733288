

$grey: #434345;
$grey-light: #DBDFE2;
$light: #F5F5ED;
$bg: #8C7B64;
$primary: #982522;
$brand: #982522;

$trans: all 0.6s ease;
/*
==============================
Fonts
==============================
*/

$ff:   'Proxima Nova', sans-serif;
$ff-med: 'Proxima Nova Medium', sans-serif;;
$ff-bold: 'Proxima Nova Bold', sans-serif;